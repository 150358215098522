import React from "react"
import { Box, Modal, Card, CardMedia, Typography } from "@mui/material"
import ReactPlayer from "react-player/lazy"

import Video from "../../types/video"

const modalSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto", // 自動で幅を調整
  maxHeight: "80vh", // ビューポートの高さの80%を最大高さとする
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflowY: "auto", // スクロールを発生させない
  display: "flex",
  flexDirection: "column",
  alignItems: "center", // 中央揃え
}

interface VideoModalEvents {
  onClose: () => void
}

interface VideoModalProps {
  isOpen: boolean
  title: string
  src: string
  relations?: Video[]
  events?: VideoModalEvents
}

const VideoModal: React.FC<VideoModalProps> = ({
  isOpen,
  title,
  src,
  relations,
  events,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={events?.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalSx}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <ReactPlayer
          url={src} // ビデオのURL
          controls // コントロールを表示
        />
        <Box sx={{ overflowX: "auto", display: "flex", padding: 2 }}>
          {relations?.map((video, index) => (
            <Card key={index} sx={{ minWidth: 200, maxWidth: 200, margin: 1 }}>
              <Box sx={{ position: "relative" }}>
                <CardMedia
                  component="img"
                  image={video.thumb}
                  alt={video.title}
                />
              </Box>
            </Card>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}

export default VideoModal
export type { VideoModalEvents, VideoModalProps }
