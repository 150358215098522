import React, { useEffect, useState } from "react"
import { Box, Typography, LinearProgress, Button } from "@mui/material"
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder"
import FavoriteIcon from "@mui/icons-material/Favorite"
import InsertCommentOutlinedIcon from "@mui/icons-material/InsertCommentOutlined"
import VideoModal from "./VideoModal"
import VideoCard from "./VideoCard"

import useUserStore from "../../store"
import ShortVideo from "../../types/shortVideo"
import recommendService from "../../services/recommendService"
import userService from "../../services/userService"
import DigestVideo from "../../types/digestVideo"

const appendBoxItemSx = {
  width: "150px",
  mt: 1,
}

const appendBoxButtonSx = {
  height: "35px",
}

const appendBoxProgressSx = {
  height: "10px",
  mb: 1,
}

const TimelineContents: React.FC = () => {
  const { user } = useUserStore()
  const [videos, setVideos] = useState<ShortVideo[]>(
    recommendService.recommendShortVideos(userService.initialUser.id)
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [modalDigest, setModalDeigst] = useState<DigestVideo | null>(null)
  // 各ビデオのいいね状態をfalseで初期化
  const [likes, setLikes] = useState(Array(videos.length).fill(false))

  useEffect(() => {
    console.log("useEffect by Timeline Component: ", user)
    if (user) {
      setVideos(recommendService.recommendShortVideos(user.id))
    }
  }, [user])

  // いいねボタンのクリックハンドラ
  const handleLikeClick = (index: number) => {
    const newLikes = [...likes]
    newLikes[index] = !newLikes[index] // 特定のビデオの状態を反転
    setLikes(newLikes)
  }

  const handleDigestClick = (index: number) => {
    const digestVideo = recommendService.getRelatedDigest(videos[index].id)
    if (digestVideo.length !== 0) {
      setModalDeigst(digestVideo[0])
      setModalOpen(true)
    }
  }

  const handleClose = () => {
    setModalOpen(false) // モーダルを閉じる
  }

  const steps = 5 // 進捗の段階数

  return (
    <Box sx={{ width: "100%", overflowY: "auto" }}>
      <Box
        sx={{
          my: 2, // 上下のマージン
          borderBottom: "1px solid", // 下線のスタイル
          borderColor: "primary.main", // 下線の色をプライマリカラーに設定
          mx: "auto", // 左右のマージンを自動で設定し、中央揃えにする
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: (theme) => theme.palette.primary.main,
            my: 2,
            mx: 2,
          }}
        >
          Timeline
        </Typography>
      </Box>
      {videos.map((video, index) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            minWidth: "50%",
            mx: 3,
            py: 6,
            borderBottom: "1px solid",
            borderColor: "#ffc0c0",
            overflowX: "hidden",
          }}
          key={index}
        >
          <VideoCard
            src={video.src}
            sx={{
              minWidth: "70%",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              px: 4,
              minWidth: "30%",
              maxWidth: "30%",
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
              }}
            >
              マッチ度
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              sx={{ ...appendBoxItemSx, ...appendBoxProgressSx }}
            >
              {Array.from({ length: steps }, (_, index) => (
                <Box key={index} width="20%" mr={index !== steps - 1 ? 0.5 : 0}>
                  <LinearProgress
                    variant="determinate"
                    value={(video.matchRate || 0) > index ? 100 : 0}
                    sx={{
                      height: 10, // バーの高さ
                      backgroundColor:
                        (video.matchRate || 0) > index
                          ? "primary.main"
                          : "grey.300", // 背景色
                    }}
                  />
                </Box>
              ))}
            </Box>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={
                likes[index] ? <FavoriteIcon /> : <FavoriteBorderIcon />
              }
              sx={{ ...appendBoxItemSx, ...appendBoxButtonSx }}
              onClick={() => handleLikeClick(index)}
            >
              いいね
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<InsertCommentOutlinedIcon />}
              sx={{ ...appendBoxItemSx, ...appendBoxButtonSx }}
              onClick={() => handleDigestClick(index)}
            >
              ダイジェスト
            </Button>
          </Box>
        </Box>
      ))}
      {modalDigest && (
        <VideoModal
          isOpen={modalOpen}
          title={modalDigest.title}
          src={modalDigest.src}
          relations={modalDigest.relations}
          events={{
            onClose: handleClose,
          }}
        />
      )}
    </Box>
  )
}

export default TimelineContents
