// theme.ts
import { createTheme } from "@mui/material/styles";

// カスタムテーマの作成
const theme = createTheme({
  palette: {
    primary: {
      main: "#D15A3E",
    },
    secondary: {
      main: "#E39C8B",
    },
  },
});

export default theme;
