import React, { useEffect } from "react"
import { Container, Box } from "@mui/material"

import Header from "../components/layout/Header"
import SideMenu from "../components/layout/SideMenu"
import DigestContents from "../components/layout/DigestContents"
import TimelineContents from "../components/layout/TimelineContents"

import useUserStore from "../store"

const RecommendPage: React.FC = () => {
  const { user } = useUserStore()
  useEffect(() => {
    console.log("useEffect by RecommendPage: ", user)
    if (user) {
      window.scrollTo(0, 0)
    }
  }, [user])

  return (
    <Container sx={{ margin: 4, maxWidth: "90% !important" }}>
      <Box display="flex">
        {/* ヘッダーコンポーネント */}
        <Header />

        <Box display="flex" maxWidth="100%">
          {/* サイドメニューコンポーネント */}
          <SideMenu />

          <Box minWidth="80%">
            <Box
              sx={{
                bgcolor: "white",
                position: "sticky",
                top: 0,
                width: "100%",
                p: 1,
                zIndex: 1000, // 高いz-indexを設定して、他の要素より前面に表示
              }}
            >
              {/* ダイジェストコンポーネント */}
              <DigestContents />
            </Box>

            {/* タイムラインコンポーネント */}
            <TimelineContents />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default RecommendPage
