import React, { useState, useEffect } from "react"
import {
  Box,
  Drawer,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  useTheme,
} from "@mui/material"
import { Radar } from "react-chartjs-2"
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js"

import Spacer from "../common/Spacer"
import useUserStore from "../../store"
import userService from "../../services/userService"

// Chart.jsのレーダーチャートのために必要な登録
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

const chartOption = {
  scales: {
    r: {
      ticks: {
        display: false, // 目盛りの数字を非表示にする
      },
    },
  },
  plugins: {
    legend: {
      display: false, // 凡例を表示する
    },
  },
}

const SideMenu: React.FC = () => {
  const { user, login } = useUserStore()
  const [selectedUser, setSelectedUser] = useState(userService.initialUser.id)

  const handleUserChange = (event: SelectChangeEvent) => {
    const userId = event.target.value as string
    const newer = userService.userList.find((u) => u.id === userId)

    if (newer) {
      login(newer)
      console.log("handle change user: ", newer)
    }
  }
  const theme = useTheme()

  // 最初のログイン
  useEffect(() => {
    login(userService.initialUser)
  }, [])

  // ユーザの切り替えごとに選択ユーザを変更
  useEffect(() => {
    if (user) {
      setSelectedUser(user.id)
    }
  }, [user])

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        width: "20%",
        flexShrink: 0,
        mt: `${theme.mixins.toolbar.minHeight}px`,
        "& .MuiDrawer-paper": {
          width: 250,
          boxSizing: "border-box",
          mt: `${theme.mixins.toolbar.minHeight}px`,
        },
      }}
    >
      <Box
        sx={{
          width: "100%", // Drawerの幅全体を使う
          display: "flex", // Flexboxを使用
          flexDirection: "column", // 子要素を縦方向に整列
          justifyContent: "center", // 子要素を中央寄せ
        }}
      >
        <Spacer
          size={30}
          axis="vertical"
          style={{ backgroundColor: "lightgrey" }}
        />
        <FormControl sx={{ margin: 1 }}>
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            labelId="user-select-label"
            id="user-select"
            value={selectedUser}
            label="User"
            onChange={handleUserChange}
          >
            {userService.userList.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Radar
          data={{
            labels: ["総合", "見た目", "音声", "言語"],
            datasets: [
              {
                label: user ? user.name : userService.initialUser.name,
                data: userService.getUserFavoritsList(
                  user ? user : userService.initialUser
                ),
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
              },
            ],
          }}
          options={chartOption}
        />
      </Box>
    </Drawer>
  )
}

export default SideMenu
