import User from "../types/user"

const userList: User[] = [
  {
    id: "user1",
    name: "User 1",
    favorites: {
      total: 65,
      visual: 59,
      voice: 90,
      words: 81,
    },
  },
  {
    id: "user2",
    name: "User 2",
    favorites: {
      total: 28,
      visual: 48,
      voice: 40,
      words: 19,
    },
  },
]

export { userList }
