import DigestVideo from "../types/digestVideo"
import ShortVideo from "../types/shortVideo"

import hukeiImage from "../assets/hukei.webp"
import moyouImage from "../assets/moyou.webp"
import tsuruImage from "../assets/tsuru.webp"
import yakeiImage from "../assets/yakei.webp"

const shortVideos: ShortVideo[] = [
  {
    id: "s1",
    title: "video1",
    src: "https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4",
    thumb: "https://via.placeholder.com/150",
    matchRate: 5,
  },
  {
    id: "s2",
    title: "video2",
    src: "https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
    thumb: "https://via.placeholder.com/150",
    matchRate: 3,
  },
  {
    id: "s3",
    title: "video3",
    src: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/720/Big_Buck_Bunny_720_10s_1MB.mp4",
    thumb: "https://via.placeholder.com/150",
    matchRate: 1,
  },
  {
    id: "s4",
    title: "Volkswagen GTI Review",
    src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/VolkswagenGTIReview.mp4",
    thumb: "https://via.placeholder.com/150",
    matchRate: 4,
  },
  {
    id: "s5",
    title: "We Are Going On Bullrun",
    src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4",
    thumb: "https://via.placeholder.com/150",
    matchRate: 3,
  },
  {
    id: "s6",
    title: "What care can you get for a grand?",
    src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WhatCarCanYouGetForAGrand.mp4",
    thumb: "https://via.placeholder.com/150",
    matchRate: 2,
  },
]

const digestVideos: DigestVideo[] = [
  {
    id: "d1",
    title: "Digest 1",
    src: "https://storage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4",
    thumb: `${yakeiImage}`,
    relations: [],
  },
  {
    id: "d2",
    title: "Digest 2",
    src: "https://storage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
    thumb: `${tsuruImage}`,
    relations: [shortVideos[0], shortVideos[5]],
  },
  {
    id: "d3",
    title: "Digest 3",
    src: "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/720/Big_Buck_Bunny_720_10s_1MB.mp4",
    thumb: `${hukeiImage}`,
    relations: [shortVideos[5]],
  },
  {
    id: "d4",
    title: "Digest 4",
    src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/VolkswagenGTIReview.mp4",
    thumb: `${moyouImage}`,
    relations: [shortVideos[1], shortVideos[3]],
  },
  {
    id: "d5",
    title: "Digest 5",
    src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WeAreGoingOnBullrun.mp4",
    thumb: `${yakeiImage}`,
    relations: [shortVideos[4]],
  },
  {
    id: "d6",
    title: "Digest 6",
    src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WhatCarCanYouGetForAGrand.mp4",
    thumb: `${tsuruImage}`,
    relations: [shortVideos[0], shortVideos[1], shortVideos[2]],
  },
]

export { shortVideos, digestVideos }
