import { create } from "zustand"
import User, { UserState } from "./types/user"

export const useUserStore = create<UserState>((set) => ({
  user: null,
  login: (user) => set({ user }),
  logout: () => set({ user: null }),
}))

export default useUserStore
