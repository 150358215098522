import { shortVideos, digestVideos } from "../data/videoData"

interface RecommendMap {
  shortVideos: string[]
  digestVideos: string[]
}

const recommendMap: Record<string, RecommendMap> = {
  user1: {
    shortVideos: ["s1", "s2", "s3"],
    digestVideos: ["d1", "d2", "d3"],
  },
  user2: {
    shortVideos: ["s4", "s5", "s6"],
    digestVideos: ["d4", "d5", "d6", "d3"],
  },
}

class RecommendService {
  recommendShortVideos(userId: string) {
    const u = recommendMap[userId]
    if (!u) {
      return []
    }

    return shortVideos.filter((sv) => u.shortVideos.includes(sv.id))
  }

  recommendDigestVideos(userId: string) {
    const u = recommendMap[userId]
    if (!u) {
      return []
    }

    return digestVideos.filter((sv) => u.digestVideos.includes(sv.id))
  }

  getRelatedDigest(shortVideoId: string) {
    const result = []

    for (const video of digestVideos) {
      const v = video.relations?.filter((rv) => shortVideoId === rv.id) || []
      if (v.length !== 0) {
        result.push(video)
      }
    }

    return result
  }

  getRelatedShort(digestVideoId: string) {
    const result =
      digestVideos.filter((video) => digestVideoId === video.id) || []

    if (result.length === 0) {
      return []
    }

    return result[0].relations || []
  }
}

export default new RecommendService()
