import { userList } from "../data/userData"
import User from "../types/user"

class UserService {
  public readonly userList: User[]
  public readonly initialUser: User

  constructor() {
    this.userList = userList
    this.initialUser = this.userList[0]
  }

  getUser(userId: string) {
    return this.userList.find((user) => user.id === userId)
  }

  getUserFavoritsList(user: User) {
    return [
      user.favorites.total,
      user.favorites.visual,
      user.favorites.voice,
      user.favorites.words,
    ]
  }
}

export default new UserService()
