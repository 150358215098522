import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import RecommendPage from "./pages/RecommendPage"
import { ThemeProvider } from "@mui/material/styles"
import theme from "./styles/theme" // カスタムテーマファイルのインポート

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<RecommendPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default App
