import React, { useEffect, useState, useRef } from "react"
import { Card } from "@mui/material"
import ReactPlayer from "react-player/lazy"

const VideoCard: React.FC<{ src: string; thumb?: string; sx?: any }> = ({
  src,
  thumb,
  sx,
}) => {
  const [play, setPlay] = useState(false)
  const playerWrapperRef = useRef(null)

  useEffect(() => {
    const playerElement = playerWrapperRef.current
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        setPlay(entry.isIntersecting)
      },
      {
        threshold: 1, // 動画が表示されたらトリガー
      }
    )

    if (playerElement) {
      observer.observe(playerElement)
    }

    return () => {
      if (playerElement) {
        observer.unobserve(playerElement)
      }
    }
  }, [])

  return (
    <Card sx={{ ...sx }} ref={playerWrapperRef}>
      <ReactPlayer
        url={src}
        playing={play}
        controls={true}
        muted={true}
        width="100%"
        height="100%"
        light={thumb} // サムネイル画像として表示
        style={{ width: "100%" }} // 動画の幅を調整
      />
    </Card>
  )
}

export default VideoCard
