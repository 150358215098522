// Header.tsx
import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Header: React.FC = () => {
  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "white",
        borderBottom: 1,
        borderColor: "primary.main",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, color: "black" }}
        >
          DEMO
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "background.paper",
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search..."
            size="small"
            sx={{ mr: 1, bgcolor: "white" }}
          />
          <IconButton
            type="submit"
            aria-label="search"
            sx={{ p: "10px", color: "primary.main" }}
          >
            <SearchIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
