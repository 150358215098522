import React, { useEffect, useState } from "react"
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Spacer from "../common/Spacer"

import { styled } from "@mui/material/styles"
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion"
import VideoModal from "./VideoModal"

import useUserStore from "../../store"
import recommendService from "../../services/recommendService"
import userService from "../../services/userService"
import DigestVideo from "../../types/digestVideo"

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}))

const DigestContents: React.FC = () => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [activeCard, setActiveCard] = useState<DigestVideo | null>(null)
  const [lastScroll, setLastScroll] = useState<number>(0)

  const { user } = useUserStore()
  const [videos, setVideos] = useState<DigestVideo[]>(
    recommendService.recommendDigestVideos(userService.initialUser.id)
  )

  useEffect(() => {
    console.log("useEffect by Digest Component: ", user)
    if (user) {
      setVideos(recommendService.recommendDigestVideos(user.id))
    }
  }, [user])

  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY
      if (currentScroll > lastScroll) {
        setExpanded(false) // 下スクロール時にAccordionを閉じる
      }
      setLastScroll(currentScroll)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [lastScroll])

  const handleOpen = (card: DigestVideo) => {
    setActiveCard(card) // アクティブなカードを設定
    setModalOpen(true) // モーダルを開く
  }

  const handleClose = () => {
    setModalOpen(false) // モーダルを閉じる
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const theme = useTheme()
  const appBarHeight = theme.mixins.toolbar.minHeight ?? 64

  return (
    <Box
      sx={{
        maxWidth: "100%",
        marginTop: `${appBarHeight}px`,
      }}
    >
      <Spacer size={30} axis="vertical" />{" "}
      <Accordion
        TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
        expanded={expanded}
        onChange={handleExpandClick}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            maxWidth: "100%",
            borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: (theme) => theme.palette.primary.main, // テキストにプライマリカラーを適用
            }}
          >
            Digest
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ overflowX: "auto", display: "flex", padding: 2 }}>
            {videos.map((card, index) => (
              <Card
                key={index}
                sx={{ minWidth: 200, maxWidth: 200, margin: 1 }}
              >
                <CardActionArea onClick={() => handleOpen(card)}>
                  <Box sx={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      image={card.thumb}
                      alt={card.title}
                    />
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        color: "white",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        width: "100%",
                        padding: "8px",
                      }}
                    >
                      {card.title}
                    </Typography>
                  </Box>
                </CardActionArea>
              </Card>
            ))}
          </Box>
          {activeCard && (
            <VideoModal
              isOpen={modalOpen}
              title={activeCard.title}
              src={activeCard.src}
              relations={activeCard.relations}
              events={{
                onClose: handleClose,
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default DigestContents
